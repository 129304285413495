import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Tooltip,
  Box,
  Button,
} from "@material-ui/core";
import axios from "axios";
import DataTable from "./DataTable";

const ConnectedDevices = ({
  device_serial,
  device_uuid,
  setLoading,
  setMessage,
  loading,
}) => {
  const [devices, setDevices] = useState([]);

  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(20);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPageSize(20);
  };

  const columns = [
    {
      field: "mac_address",
      headerName: "MAC Address",
      filterable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "ip_address",
      headerName: "IP Address",
      filterable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "hostname",
      headerName: "Host",
      filterable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "signal_strength",
      headerName: "Signal Strength",
      filterable: false,
      sortable: false,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (device_uuid !== null && open) {
      setLoading(true);
      axios
        .get(`/device/${device_uuid}/connection_details`)
        .then(({ data }) => {
          setDevices(data.connected_devices);
          setLoading(false);
        })
        .catch((error) => {
          console.log({ error });
          setMessage({ type: "error", text: error.response.data.detail });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [open, pageSize]);

  return (
    <>
      <Tooltip title="See Connected Devices">
        <Button
          style={{ cursor: "pointer", marginLeft: "5px", padding: 4 }}
          onClick={handleClickOpen}
          variant="contained"
        >
          See Devices
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="simple-dialog-title">
          {device_serial} Connected Devices
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataTable
              columns={columns}
              data={devices}
              loading={loading}
              paginationMode="client"
              getRowId={(row) => row.mac_address}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectedDevices;
