import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Tooltip,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import DataTable from "./DataTable";
import moment from "moment";

const ServingCellHistory = ({
  device_serial,
  device_uuid,
  setLoading,
  setMessage,
  loading,
}) => {
  const [servingCellHistory, setServingCellHistory] = useState([]);

  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 20,
    total: 1,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPageSize(20);
    setCurrentPage(1);
  };

  const columns = [
    {
      field: "sci_time",
      headerName: "Date",
      filterable: false,
      sortable: false,
      flex: 2,
      valueGetter: (params) => moment(params.row?.sci_time * 1000).local(),
    },
    {
      field: "sci_ca_info",
      headerName: "Serving Cell Info",
      filterable: false,
      sortable: false,
      flex: 5,
      renderCell: (params) => (
        <Box
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            overflowWrap: "anywhere",
            lineHeight: "1.5",
          }}
        >
          {params.row.sci_ca_info}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (device_uuid !== null && open) {
      setLoading(true);
      axios
        .get(
          `/device/${device_uuid}/serving_cell_info?page=${currentPage}&page_size=${pageSize}`
        )
        .then(({ data }) => {
          setServingCellHistory(data.items);
          setMetaData({
            page: data.page,
            page_size: data.size,
            total: data.total,
          });
          setLoading(false);
        })
        .catch((error) => {
          setMessage({ type: "error", text: error.response.data.detail });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [open, pageSize, currentPage]);

  return (
    <>
      <Tooltip title="See Serving Cell History">
        <Button
          style={{ cursor: "pointer", marginLeft: "5px", padding: 4 }}
          onClick={handleClickOpen}
          variant="contained"
        >
          See History
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="simple-dialog-title">
          {device_serial} Serving Cell History
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: 400, width: "100%" }}>
            {servingCellHistory ? (
              <DataTable
                columns={columns}
                data={servingCellHistory}
                loading={loading}
                metaData={metaData}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
              />
            ) : (
              <Typography>Please wait...</Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ServingCellHistory;
